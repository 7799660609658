<template>
    <div>
    <v-card class="mb-7 text-center" v-show="receiving">
        <v-btn @click="startStream()" v-if="isSpeaker && streaming==false" class="ma-3">Начать трансляцю</v-btn>
                <div  v-if="receiving && videoTime==0" style="position:absolute; top:25px; width:100%; z-index:1;"> <center>Идёт трансляция</center>
        <v-btn   class="ma-3">Войти</v-btn>
        </div>
        <div  v-show="streaming || receiving">
            <video @timeupdate='videoUpdateTime' :srcObject="myStream" style="width:100%; height:200px;" autoplay="autoplay" :muted="isSpeaker" class="center-block"></video>
            <video v-for="(stream,index) of streams" :srcObject="stream" :key="index" ref="streamg" style="width:100%; height:200px;" autoplay="autoplay" :muted="isSpeaker" class="center-block"></video>
            <div v-if="streaming==true">
                <v-btn icon @click="clear()"><v-icon>mdi-stop</v-icon></v-btn>
                <v-btn icon @click="onShareScreen()"><v-icon color="primary">mdi-monitor-share</v-icon></v-btn>
                <v-btn icon @click="mute=!mute"><v-icon v-if="mute" color="error">mdi-microphone-off</v-icon><v-icon v-else color="primary">mdi-microphone</v-icon></v-btn>
                <v-btn icon @click="stopVideo=!stopVideo"><v-icon v-if="stopVideo" color="error">mdi-video-off</v-icon><v-icon v-else color="primary">mdi-video</v-icon></v-btn>
                {{peers.length}}
            </div> 
            <v-btn @click="fullScreenOn()" v-if="!isSpeaker && receiving==true" style="ma-0"><v-icon>mdi-monitor-screenshot</v-icon></v-btn>
        </div>
    </v-card>
    </div>
</template>
<script>
    import Peer from 'peerjs';
    export default {
        name: "index",
        props: {
            lessonId: String,
            speakerId: String
        },
        data: () => ({
                isSpeaker: false,
                peerId: null,
                peersCount: 0,
                peers: [],
                peer: null,
                streaming: false,
                receiving: false,
                withAudio: true,
                withVideo: true,
                cameraStream: null,
                streams:[],
                myStream:null,
                video: {},
                conn: {},
                peer_id: null,
                connections: [],
                mute: false,
                stopVideo: false,
                firstLoad:true,
                videoTime:0
            }),
        watch: {
            '$store.state.lessonRoom.isSpeaker'() {
                
                this.$set(this, 'isSpeaker', this.$store.state.lessonRoom.isSpeaker)
            },
            '$store.state.lessonRoom.peerId'() {
                this.$emit('fullScreenClose')
                if (!this.isSpeaker) {
                        this.connectToPeer();
                }
            },
            streaming() {
                this.$nextTick(() => {
                    this.$emit('updateHeight')
                })
            },
            receiving() {
                this.$nextTick(() => {
                    this.$emit('updateHeight')
                })
            },
            mute() {
                if (!this.isSpeaker) {
                    return
                }
                this.$nextTick(() => {
                    console.log(this.mute)
                    if (this.mute) {
                        this.myStream.getAudioTracks()[0].enabled = false;
                    } else {
                        this.myStream.getAudioTracks()[0].enabled = true;
                    }
                });
            },
            stopVideo() {
                if (!this.isSpeaker) {
                    return
                }
                this.$nextTick(() => {
                    if (this.stopVideo) {
                        this.myStream.getVideoTracks()[0].enabled = false;
                    } else {
                        this.myStream.getVideoTracks()[0].enabled = true;
                    }
                });

            },
        },
        
        methods: {
            videoUpdateTime(ev){
              this.videoTime= ev.target.currentTime
            },
            fullScreenOn() {
                if (this.isSpeaker)
                {
                    this.$emit('fullScreenOpen', this.myStream.cameraStream)
                } else {
                    this.$emit('fullScreenOpen', this.stream[0].peer_stream)
                }
            },
            fullScreenOff() {
                this.$emit('fullScreenClose')
            },
            connectToPeer() {

                var username = 'sdfsd';
                if (this.peer_id && this.peer) {
                    let that = this
                    this.conn = this.peer.connect(this.peer_id, {
                        metadata: {
                            'username': username
                        }
                    });
                    
                    this.conn.on('close', function () {

                        that.receiving = false

                    })
                }
            },
            requestLocalVideo(callbacks) {
                // Monkeypatch for crossbrowser geusermedia
                navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

                // Request audio an video
                navigator.getUserMedia({audio: this.withAudio, video: this.withVideo}, callbacks.success, callbacks.error);
                console.log(callbacks)
            },
            onShareScreen() {

                navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

                navigator.mediaDevices.getDisplayMedia({video: true}).then((stream) => {
                    var that = this
                    that.requestLocalVideo({
                        success: function (audioStream) {
                            stream.addTrack(audioStream.getAudioTracks()[0]);
                            for (let peer of that.peers) {
                                that.peer.call(peer, stream);
                            }
                        },
                        error: function (err) {
                            alert("Cannot get access to your camera and video !");
                            console.error(err);
                        }
                    })
                })
            },
            startStream() {
                this.clear()
                this.init()
                
                let that = this
                this.requestLocalVideo({
                    success: function (stream) {
                        that.myStream = stream;
                        that.streamToAll()
                    },
                    error: function () {
                        that.withVideo = false;
                        that.requestLocalVideo({
                            success: function (stream) {
                                that.myStream = stream;
                                that.streamToAll()
                            },
                            error: function () {
                                that.withAudio = false;
                                that.requestLocalVideo({
                                    success: function (stream) {
                                        that.myStream = stream;
                                        that.streamToAll()
                                    },
                                    error: function (err) {
                                        alert("Cannot get access to your camera and video !");
                                        console.error(err);
                                    }
                                })
                            }
                        })

                    }
                });

            },
            streamToAll() {
                this.streaming = true
                let that=this
                for (let peer of this.peers) {
                    this.peer.call(peer, that.myStream)
                }
            },
            stopBothVideoAndAudio(stream) {
                if (stream) {
                    stream.getTracks().forEach(function (track) {
                        if (track.readyState == 'live') {
                            track.stop();
                        }
                    });
                }
            },

// stop only camera
            stopVideoOnly(stream) {
                if (stream) {
                    stream.getTracks().forEach(function (track) {
                        if (track.readyState == 'live' && track.kind === 'video') {
                            track.stop();
                        }
                    });
                }
            },

// stop only mic
            stopAudioOnly(stream) {
                if (stream) {
                    stream.getTracks().forEach(function (track) {
                        if (track.readyState == 'live' && track.kind === 'audio') {
                            track.stop();
                        }
                    });
                }
            },
            clear() {
                this.stopBothVideoAndAudio(this.myStream)
                for(var stream of this.streams)
                this.stopBothVideoAndAudio(stream)
                if(this.peer && typeof this.peer.id !== 'undefined'){
                    this.peer.destroy()
                }
                this.streaming = false
                this.receiving = false

            },
            makeid(length) {
                var result = '';
                var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for (var i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() *
                            charactersLength));
                }
                return result;
            },
            async init() {
                await this.startStream()
                let that = this
                this.peer = new Peer({
                    id: that.makeid(20),
                    host: "stream.eios-mchs.ru",
                    path: '/peerjs',
                    debug: 0,
                    config: {
                        iceServers: [{
                                urls: 'turn:195.211.195.208:3478',
                                username: 'user',
                                credential: 'test'
                            }]
                    }
                })

                this.peer.on('open', (id) => {
                    that.peerId = id;
                    that.startStream();
                    if (that.isSpeaker) {
                        that.$socket.send(JSON.stringify({action: 'setPeerId', id: that.$route.params.id, peerId: that.peerId}))
                    } else {
                        that.$nextTick(()=>{
                            that.connectToPeer()
                        })
                        
                    }
                    
                });

                this.peer.on('call', function (call) {
                    // Answer the call with your own video/audio stream
                    call.answer(that.myStream);
                    this.streaming=true
                    // Receive data
                    call.on('stream', function (stream) {
                        this.streams.push(stream)
                        that.receiving = true
                        
                    });
                });
                this.peer.on('connection', (conn) => {
                    if (that.streaming) {
                        that.peers.push(conn.peer)

                        this.peer.call(conn.peer, that.myStream)
                        conn.on('close', () => {
                            var index = that.peers.findIndex(el => el == conn.peer)
                            that.peers.splice(index, 1)
                        })
                    }
                });

                this.peer.on('close', () => {

                    console.log('close');
                });
                this.peer.on('error', () => {

                });
                this.peer.on('disconnected', () => {

                    console.log('dis');
                });
            }
        },
        mounted() {

            if (this.$auth.user().id == this.speakerId) {
                this.isSpeaker = true
            }
            this.$nextTick(()=>{
               // this.init()
            })
            
        },
        beforeDestroy: function () {

            this.clear()
            this.$store.state.lessonRoom.peerId=null
        },
        components: {

        }
    };
</script>