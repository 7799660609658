var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-lazy',[(_vm.video.coded)?_c('video-player',{ref:"videoPlayer",staticClass:"video-player-box",staticStyle:{"width":"100%"},attrs:{"options":{
                  muted: false,
                  language: "ru",
                  sources: [{
                  type: "video/mp4",
                  src: _vm.video.coded
                  }],
                  poster: _vm.video.preview,
                  coding:false,
                  height: _vm.height
                  },"playsinline":true}}):_c('div',[_c('h1',[_vm._v("Видео кодируется. Зайдите пожалуйста позднее.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }