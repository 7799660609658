<template>
    <v-lazy>
        <video-player v-if="video.coded" class="video-player-box"
                      ref="videoPlayer"
                      :options='{
                      muted: false,
                      language: "ru",
                      sources: [{
                      type: "video/mp4",
                      src: video.coded
                      }],
                      poster: video.preview,
                      coding:false,
                      height: height
                      }'
                      :playsinline="true"
                      style="width:100%; "

                      >

        </video-player>
        <div v-else><h1>Видео кодируется. Зайдите пожалуйста позднее.</h1></div>
    </v-lazy>
</template>
<style>
    .vjs-big-play-button{
        top: 50% !important;
        left: 50% !important;
        margin-left: -45px;
        margin-top: -22px;
    }
    .video-js{
        width:100%;

    }
</style>
<script>
    import 'video.js/dist/video-js.css'
    import 'videojs-contrib-hls';
    import { videoPlayer } from 'vue-video-player'
    export default {
        name: "index",
        props: {
            video: Object,
            height: String
        },
        watch: {
            'height'() {
                if (this.videoPlayer) {
                    this.videoPlayer.style.height = this.height
                }
            }
        },
        data: () => ({
                loaded: false,
            }),
        components: {
            videoPlayer
        }
    };
</script>