<template>
    <div ref="main" >
        <div style="display:block;">
            <v-img v-if="question.file" contain class="ma-3" max-height="300" :src="question.file">
                <v-btn v-if="editMod" small fab class="ma-1" @click="removeFile()"><v-icon color="error">mdi-delete</v-icon></v-btn>
            </v-img>
        </div>
        <v-btn v-if="!question.file" fab @click="fileModal=true"><v-icon color="green">mdi-upload</v-icon></v-btn>
        <h3 class='ma-3'><div v-if="editMod" style="position: relative;"><v-textarea :background-color="question.text!=tempQuestion.text?'amber lighten-4':''" solo dense outlined hide-details="auto" :rows="1" auto-grow v-model="tempQuestion.text"></v-textarea><v-btn @click="qustionSetText()" v-show="question.text!=tempQuestion.text" color="green" icon style="position:absolute;right:0px; top:1px;z-index:1"><v-icon class="mx-0">mdi-content-save</v-icon></v-btn></div><span v-else>{{question.text}}</span></h3>
        <v-row class="text-center px-7 pt-3" v-if="editMod">

            <v-col cols="6" v-for="answer in question.answers" :key="answer.id">
                <v-card elevation="2" class="pa-3"
                        shaped>
                    <v-row align="center"><v-col cols="auto" class="pa-0 pl-1">
                            <v-checkbox class="ma-0"
                                        multiple
                                        @change="answerSetCorrect()"
                                        v-model="question.correct"
                                        color="green"
                                        :value="answer.id"
                                        hide-details
                                        ></v-checkbox>
                        </v-col>
                        <v-col class="px-0">
                            <v-textarea  v-if="answer.id==curAnswer.id" :background-color="answer.text!=curAnswer.text?'amber lighten-4':''" solo dense outlined hide-details="auto" :rows="1" auto-grow v-model="curAnswer.text"></v-textarea>
                            <span v-else>{{answer.text}}</span>
                        </v-col>
                        <v-col cols="auto" class='pa-0 ma-0'>{{answer.edit}}
                            <v-btn icon color="primary" @click="curAnswer=JSON.parse(JSON.stringify(answer))" v-if="answer.id!=curAnswer.id"><v-icon>mdi-pencil</v-icon></v-btn>
                            <v-btn icon color="primary" @click="answerSetText" v-else><v-icon>mdi-content-save</v-icon></v-btn>
                            <v-btn icon color="error" @click="curAnswer=answer;answerDeleteModal=true" v-if="answer.id!=curAnswer.id"><v-icon>mdi-delete</v-icon></v-btn>
                            <v-btn icon color="error" @click="curAnswer={}" v-else><v-icon>mdi-cancel</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <v-col cols="6"  v-if="editMod">
                <v-card elevation="2" class="pa-3" shaped>
                    <v-btn color="primary" @click="answerAdd()">Добавить ответ</v-btn>     
                </v-card>
            </v-col>
        </v-row>
        <v-row class="text-center px-7 pt-3" v-else>

            <v-col cols="6" v-for="answer in question.answers" :key="answer.id">
                <v-card elevation="2" class="pa-3"
                        shaped>

                              <v-checkbox class="ma-0"
                                        multiple
                                        :label="answer.text"
                                        v-model="question.correct"
                                        color="green"
                                        :value="answer.id"
                                        hide-details
                                        ></v-checkbox>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="answerDeleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>После удаления восстановление ответа невозможно.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="answerDeleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="answerDelete()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model='fileModal' max-width="400">
            <v-card>
                <v-card-title class="headline">Добавить документ</v-card-title>
                <v-card-text>
                    <v-overlay
                        absolute
                        :value="uploading"
                        >
                    </v-overlay> 
                    <v-list-item-subtitle class="text-wrap">
                        Прикрепите документ и нажмите загрузить
                    </v-list-item-subtitle>
                    <div class="mt-4">
                        <v-file-input show-size v-model="file" label="Файл" accept=".jpeg, .jpg, .png"></v-file-input>
                    </div>
                    <v-btn color="primary" v-if="!uploading" @click="upload()">Загрузить</v-btn>
                    <v-progress-linear style="width:100%"
                                       v-if="uploading"
                                       v-model="uploadPercentage"
                                       height="25"
                                       >
                        <template v-slot:default="{ value }">
                            <strong>{{ Math.ceil(value) }}%</strong>
                        </template>
                    </v-progress-linear>
                </v-card-text>

            </v-card>

        </v-dialog>    
    </div>
</template>
<style>
    #question textarea{
        display: block;
        text-align: center;
    }
    #question .v-responsive__content{
        width: auto !important;
    }
</style>

<script>
    export default {
        name: "index",
        props: {
            blockId: String,
            editMod: Boolean,
            block: Object,
            height: String,
            question: Object
        },
        data: () => ({
                tempQuestion: {},
                page: 1,
                curAnswer: {},
                answerDeleteModal: false,
                fileModal: false,
                file: null,
                uploading: false,
                uploadPercentage: 0
            }),
        watch: {
            'question'() {
                this.tempQuestion = JSON.parse(JSON.stringify(this.question))
            }
        },
        methods: {
            qustionSetText() {
                this.$http.post('test/set-text?questionId=' + this.tempQuestion.id,
                        {
                            text: this.tempQuestion.text
                        }).then((response) => {
                    this.$set(this.question, 'text', response.data.text)
                })
            },
            addFile() {
                this.question.file = 'https://miro.medium.com/max/4936/1*svd2l7yAE-8LV7pR4bnVIA.png'
            },
            removeFile() {
                this.$http.post('test/file-delete?questionId=' + this.question.id).then(() => {
                    this.question.file = null
                })

            },
            upload() {
                this.uploading = true
                var formData = new FormData()
                formData.append("file", this.file)
                this.$http.post('test/file-upload?questionId=' + this.question.id,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function (progressEvent) {
                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                            }.bind(this)
                        }
                ).then(response => {
                    this.uploading = false
                    this.fileModal = false
                    this.question.file = response.data
                }).catch(e => {
                    this.uploading = false
                    alert(e.response.data)
                })
            },
            answerAdd() {
                this.$http.get('test/answer-add',
                        {
                            params: {
                                questionId: this.question.id
                            }
                        }).then((response) => {
                    if (!this.question.answers) {
                        this.$set(this.question, 'answers', [])
                    }
                    this.question.answers.push(response.data)
                })
            },
            answerSetText() {
                this.$http.post('test/answer-set-text?answerId=' + this.curAnswer.id,
                        {
                            text: this.curAnswer.text
                        }).then((response) => {
                    var index = this.question.answers.findIndex(el => el.id == this.curAnswer.id)
                    this.$set(this.question.answers[index], 'text', response.data.text)
                    this.curAnswer = {}
                })
            },
            answerDelete() {
                this.$http.get('test/answer-delete',
                        {
                            params: {
                                answerId: this.curAnswer.id
                            }
                        }).then(() => {
                    var index = this.question.answers.findIndex(el => el.id == this.curAnswer.id)
                    this.question.answers.splice(index, 1)
                    this.page = index - 1
                    this.answerDeleteModal = false
                })

            },
            answerSetCorrect() {
                this.$http.post('test/answer-set-correct?questionId=' + this.question.id,
                        {
                            correct: this.question.correct
                        }).then((response) => {
                    this.$set(this.question, 'correct', response.data)
                })

            },
        },
        components: {
        },
        mounted() {
            this.tempQuestion = JSON.parse(JSON.stringify(this.question))
            this.$refs.main.style.height = this.height
        }
    };
</script>