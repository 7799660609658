<template>
    <div ref="main" >
        <div v-if="!attemp">
            <v-card
                class="elevation-16 mx-auto"
                width="500"
                >
                <div v-if="attempsData.unDone">
                    <v-card-title class="text-h5">
                        В процессе
                    </v-card-title>
                    <v-card-text>
                        Вы не завершели это тестирование.
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="justify-space-between">
                        <v-btn
                            color="primary"
                            text
                            @click="startTest()">Продолжить</v-btn>
                    </v-card-actions>
                </div>
                <div v-else>
                    <v-card-title class="text-h5">
                        <span v-if="attempsData.attemps.length>0">Результаты</span><span v-else>Тестирование</span>
                    </v-card-title>
                    <v-card-text v-if="attempsData.attemps">

                        <div v-for="(attemp,index) in attempsData.attemps" :key="attemp.id">
                            <h3>Попытка {{index+1}}</h3>
                            Вопросов: {{attemp.countQuestions}}<br>
                            Правильных ответов: {{attemp.countCorrect}}
                            <div class="text-center mt-3">
                                <v-rating
                                    :value="5*attemp.countCorrect/attemp.countQuestions"
                                    color="yellow darken-3"
                                    background-color="grey darken-1"
                                    half-increments
                                    readonly
                                    hover
                                    large
                                    ></v-rating>
                            </div>
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions v-if="attempsData.attempsCount>0">

                        <v-btn 
                            color="primary"
                            text
                            @click="startTest()">
                            <span v-if="attempsData.attemps.length>0">
                                Попробовать ещё раз
                            </span>
                            <span v-else>Приступить</span>&nbsp;&nbsp;(попыток: {{attempsData.attempsCount}})
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </div>
        <div v-else>
            <v-lazy >
                <div id="question">
                    <v-toolbar flat tile >
                        <v-toolbar-title><v-tabs :show-arrows="true" v-model="page"><v-tab class="pa-0 ma-0" style="min-width:0px;" v-for="(pageQuestion,index) in questions" :key="pageQuestion.id" @change="question=pageQuestion" @click="question=pageQuestion"><v-btn :outlined='!pageQuestion.correct' :color='pageQuestion.correct?"blue ":"gray"' tile>{{index+1}}</v-btn></v-tab></v-tabs>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn-toggle  tile v-if="editMod"><v-btn outlined  tile style='height:36px;' @click="addQuestion()"><v-icon color="green">mdi-plus</v-icon></v-btn><v-btn outlined tile style='height:36px;' v-if="question" @click="questionDeleteModal=true"><v-icon color="error">mdi-delete</v-icon></v-btn></v-btn-toggle>
                    </v-toolbar>
                    <h1 v-if="questions.length==0">Вопросов нет.</h1>
                    <v-btn v-if="editMod && questions.length==0" @click="addQuestion()">Добавить</v-btn>
                    <questionProccess v-if="Object.keys(question).length!=0" :question.sync="question" :editMod="editMod"  @nextQuestion='nextQuestion()'/>
                    <v-btn @click="doneModal=true" :color="countUndone>0? 'red':'green'">Завершить тестирование</v-btn>
                </div>
            </v-lazy>
        </div>
        <v-dialog v-model="doneModal" persistent max-width="600">
            <v-card>
                <v-card-title class="headline">Завершить тестирование</v-card-title>
                <v-card-text v-if='countUndone>0'>Вы ответили не на все вопросы. Вы действительно хотите завершить тестирование?</v-card-text>
                <v-card-text v-else>Вы уверены что выбрали правильные ответы?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="doneModal = false">Я ещё подумаю</v-btn>
                    <v-btn color="primary" text @click="done()">Завершить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import questionProccess from './questionProccess'
    export default {
        name: "index",
        props: {
            blockId: String,
            editMod: Boolean,
            height: String,
            hideControls: Boolean
        },
        data: () => ({
                doneModal: false,
                attempsData: null,
                attemp: null,
                page: 0,
                questionDeleteModal: false,
                questions: [],
                question: {},
            }),
        computed: {
            countUndone() {
                var count = 0
                this.questions.forEach(function (item) {
                    if (item.correct) {
                        count++;
                    }
                });
                count = this.questions.length - count
                return count
            }
        },
        methods: {
            nextQuestion() {
                let index = this.questions.findIndex(el => el.id == this.question.id)
                if (this.questions[index + 1])
                {
                    this.$set(this, 'question', this.questions[index + 1])
                    this.page = index + 1
                } else {
                    this.$set(this, 'question', this.questions[0])
                    this.page = 0
                }
            },
            getAttemps() {
                this.$http.get('survey/get-attemps',
                        {
                            params: {
                                timetableId: this.$route.params.id,
                                blockId: this.blockId
                            }
                        }).then((response) => {
                    this.attempsData = response.data
                })
            },
            done() {
                this.$http.post('survey/done?attempId=' + this.attemp.id,
                        this.questions
                        ).then(() => {
                    this.attemp = null
                    this.doneModal = false
                    this.$emit("update:hideControls", false)
                    this.getAttemps()
                }).catch(e => {
                    alert(e.response.data)
                })
            },
            startTest() {
                this.$http.get('survey/get-questions',
                        {
                            params: {
                                timetableId: this.$route.params.id,
                                blockId: this.blockId
                            }
                        }).then((response) => {
                    this.$emit("update:hideControls", true)
                    this.attemp = response.data.attemp
                    this.questions = response.data.questions
                    if (this.questions.length > 0) {
                        this.question = this.questions[0]
                    }
                })
            }
        },
        components: {
            questionProccess
        },
        mounted() {
            this.getAttemps()
            this.$refs.main.style.height = this.height
        }
    };
</script>