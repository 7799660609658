<template>
    <v-lazy>
  <iframe :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + block.file" width="100%" :height="height" frameborder='0'>This is an embedded <a target='_blank' href='https://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='https://office.com/webapps'>Office Online</a>.</iframe>
  </v-lazy>
</template>

<script>
    export default {
        name: "index",
        props:{
            block:Object,
            height:String
        },
        data: () => ({
            }),

        components: {
        },
        mounted() {
        }
    };
</script>