<template>
    <div v-if='lesson && lesson.topic'>
        <helper :groupId="4" :autostart="true" :closeOnEnd='true'/>
        <div v-if='instruction==0'>
            <v-card >
                <v-card-title>Инструктаж</v-card-title>
                <v-card-text v-html='lesson.instructionText.text'></v-card-text>
                <v-card-action><center><v-btn class="ma-3" color="primary" @click="instructionAccept()">С инструктажом ознакомлен</v-btn></center></v-card-action>
            </v-card>
        </div>
        <div fluid v-else>
            <v-row>
                <v-col cols="9" class="pb-0">
                    <v-card  class="mb-7 pa-3"  lazy>
                        <div ref="headBlock">
                            <v-row><v-col cols='auto'><v-btn color="primary"  @click="$router.go(-1)" ><v-icon 
                                            class=" mr-3">fa fa-arrow-circle-left</v-icon>Выйти</v-btn></v-col><v-col>{{lesson.topic.section.course.name}} / {{lesson.topic.name}} / {{lesson.name}} </v-col></v-row>
                        </div>
                        <div ref="lessonWindow">
                            <div v-for="(file,index) in lesson.files" :key="index" v-show="fileView==index" class='ma-3'>

                                <h3>{{file.name}}</h3>
                                <v-lazy><iframe :height="blockHeight+50+'px'" v-if='file.type=="docx" || file.type=="pptx"' :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + file.file" width="100%" frameborder='0'>This is an embedded <a target='_blank' href='https://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='https://office.com/webapps'>Office Online</a>.</iframe></v-lazy>
                                <v-lazy><object  v-if='file.type=="pdf"'><embed :src="file.file" width="100%" :height="blockHeight+50+'px'" /></object></v-lazy>
                            </div>
                            <div v-if="fileView=='fullVideo'">
                                <video id="fullVideo" style="width:100%; height:100%;" autoplay="autoplay" class="center-block"></video>
                            </div>
                            <v-stepper v-show="fileView==null" v-model="step" class="mt-3" alt-labels ref='stepper' style="overflow-x: hidden;
                                       overflow-y: auto;height:100%;">
                                <v-stepper-header style="height:auto;">
                                    <template v-for="(block,index) in lesson.blocks">
                                        <v-tooltip top :key="index">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >
                                                    <v-stepper-step class="py-1" 

                                                                    :id="'step'+index"
                                                                    :step="index+1"
                                                                    editable

                                                                    >  
                                                        <span v-if="block.type=='video'">Видео</span>
                                                        <span v-if="block.type=='wordText'">Документ</span>
                                                        <span v-if="block.type=='prezi'">Презентация</span>
                                                        <span v-if="block.type=='pdf'">Документ</span>
                                                        <span v-if="block.type=='test'">Тестирование</span>
                                                    </v-stepper-step> </span>

                                            </template>{{block.name}}

                                        </v-tooltip>
                                        <v-menu v-if='editMod'
                                                :open-on-hover='true'
                                                :key="'menu'+block.id"
                                                :activator="'#step'+index"
                                                offset-y
                                                >

                                            <v-list>
                                                <v-list-item
                                                    >
                                                    <v-btn  small class="ma-1" size="large"  v-if="index>0" @click='blockMoveBack(index)'><v-icon>mdi-arrow-left-bold</v-icon></v-btn>
                                                    <v-btn  small class="ma-1" size="large" v-if="index<lesson.blocks.length-1" @click='blockMoveForward(index)'><v-icon>mdi-arrow-right-bold</v-icon></v-btn>
                                                    <v-btn  small class="ma-1" color="primary" size="large" @click="blockTemp=JSON.parse(JSON.stringify(block)); blockRenameModal=true"><v-icon>mdi-pencil</v-icon></v-btn>
                                                    <v-btn  small class="ma-1" color="error" size="large" @click="blockTemp=block; blockDeleteModal=true"><v-icon>mdi-delete</v-icon></v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-divider class="py-1" :key="block.id" style="margin: 16px -67px 0;" v-if='!(!editMod && index==lesson.blocks.length-1)'></v-divider>
                                    </template>
                                    <v-stepper-step v-if="editMod" class="py-1"
                                                    edit-icon='mdi-plus'
                                                    :step="lesson.blocks.length+1"
                                                    editable
                                                    complete
                                                    >
                                        Добавить блок

                                    </v-stepper-step> 
                                </v-stepper-header>

                                <v-stepper-items>
                                    <v-stepper-content v-for="(block,index) in lesson.blocks" :step="index+1" :key="block.id" :title="block.name" style="padding:0px;">
                                        <v-row><v-col cols="auto"> 
                                                <v-btn

                                                    class='ma-3'
                                                    style="position: absolute;top: 300px; left: 0px; z-index:1" fab
                                                    v-if="index>0 && !hideControls"
                                                    color="primary"
                                                    @click="step = step-1"
                                                    >
                                                    <v-icon large>mdi-arrow-left-drop-circle</v-icon>

                                                </v-btn>
                                            </v-col>
                                            <v-col>
                                                <div class="text-center" >

                                                    <videoComponent v-if='block.type=="video"' :height="blockHeight+'px'" :video="block"></videoComponent>
                                                    <wordComponent v-if='block.type=="wordText"'  :height="blockHeight+'px'" :block="block"></wordComponent>
                                                    <preziComponent v-if='block.type=="prezi"' :height="blockHeight+'px'" :block="block"></preziComponent>
                                                    <testComponent v-if='block.type=="test" && editMod' :blockId="block.id" :editMod="editMod" :height="blockHeight+'px'" :block="block"></testComponent>
                                                    <testProccess v-if='block.type=="test" && !editMod' :hideControls.sync="hideControls" :blockId="block.id" :editMod="editMod" :height="blockHeight+'px'" :block="block"></testProccess>
                                                    <object  v-if='block.type=="pdf"'><embed :src="block.file" width="100%" :height="blockHeight+'px'" /></object>

                                                </div>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn

                                                    class='ma-3'
                                                    style="position: absolute;top: 300px; right: 0px;" fab
                                                    v-if="index<lesson.blocks.length-1  && !hideControls"
                                                    color="primary"
                                                    @click="step = step+1"
                                                    >
                                                    <v-icon large>mdi-arrow-right-drop-circle</v-icon>

                                                </v-btn>
                                                <v-btn

                                                    class='ma-3'
                                                    style="position: absolute;top: 300px; right: 0px;" fab
                                                    v-else-if="!hideControls"
                                                    color="primary"
                                                    @click="finishLesson()"
                                                    >
                                                    <v-icon large>mdi-check-circle-outline</v-icon>

                                                </v-btn>                                            
                                            </v-col>
                                        </v-row>
                                    </v-stepper-content>
                                    <v-stepper-content :step="lesson.blocks.length+1" v-if="editMod">
                                        <newLessonBlock :blocks.sync="lesson.blocks" :lessonId='lesson.id' :step.sync='step'/>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="3"  class="pb-0">
                    <div ref="upBlock" v-if='!editMod'>
                        <videoChat @fullScreenOpen="videoOpen" ref="videoChat" :lessonId="$route.params.id" :speakerId="lesson.creator" @fullScreenClose="videoClose" @updateHeight="matchHeight()">
                        </videoChat >
                    </div>
                    <div v-else ref="upBlock"/>
                    <v-card class="mb-7">
                        <v-tabs 
                            id='tabs'
                            v-model="tab"
                            background-color="blue accent-4"
                            centered
                            dark
                            icons-and-text
                            >
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab>
                                Урок
                                <v-icon>mdi-book-open-page-variant-outline</v-icon>
                            </v-tab>

                            <v-tab v-if='!editMod'>
                                Люди
                                <v-icon>mdi-account-group</v-icon>
                            </v-tab>

                            <v-tab v-if='!editMod' @change="matchHeight()">
                                Чат
                                <v-icon>mdi-chat</v-icon>
                            </v-tab>
                        </v-tabs>
                        <div ref="biblio">
                            <v-tabs-items v-model="tab">

                                <v-tab-item
                                    :key="0"
                                    >
                                    <v-list>
                                        <v-list-item-group 
                                            color="primary">
                                            <v-list-item
                                                :disabled="fileView==null"
                                                @click="fileView=null"
                                                >
                                                <v-list-item-icon>
                                                    <v-icon color='primary'>fa fa-book</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Урок</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item v-if='lesson.instruction'
                                                @click="instruction=0"
                                                >
                                                <v-list-item-icon>
                                                    <v-icon color='primary'>mdi-book-information-variant</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Инструктаж</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item
         
                                                >
                                                <v-list-item-content>
                                                    <center><v-btn @click="questionDialog=true">Вопрос преподавателю</v-btn></center>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                        <span class='title font-weight-regular pl-3'>Библиотека</span>
                                        <v-list-item-group
                                            id='blblioBlock'
                                            color="primary">
                                            <v-tooltip left v-for="(file,index) in lesson.files" :key="index">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-list-item
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click="showFile(index)"
                                                        :disabled="fileView==index"
                                                        :alt="file.name"
                                                        >
                                                        <v-list-item-icon>
                                                            <v-icon v-if="file.type=='pdf'"
                                                                    class="red--text"
                                                                    >far fa-file-pdf</v-icon>
                                                            <v-icon v-if="file.type=='docx'"
                                                                    class="blue--text"
                                                                    >far fa-file-word</v-icon>
                                                            <v-icon v-if="file.type=='pptx'"
                                                                    style="color:#d86043"
                                                                    >far fa-file-powerpoint</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-text="file.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action v-if="editMod">
                                                            <div>
                                                                <v-btn icon @click.prevent.stop="fileTemp=JSON.parse(JSON.stringify(file)); fileRenameModal=true">
                                                                    <v-icon color="primary lighten-1">mdi-pencil</v-icon>
                                                                </v-btn>
                                                                <v-btn icon @click.prevent.stop="fileTemp=file; fileDeleteModal=true">
                                                                    <v-icon color="error lighten-1">mdi-delete</v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </template>
                                                <span>{{file.name}}</span>
                                            </v-tooltip>
                                            <v-list-item v-if="editMod"
                                                         @click="fileModal=true"
                                                         >
                                                <v-list-item-icon>
                                                    <v-icon color='primary'
                                                            >mdi-plus-thick</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Добавить документ</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-tab-item>

                                <v-tab-item
                                    :key="1"
                                    >
                                    <v-list>
                                        <v-list-item-group

                                            color="primary">
                                            <v-tooltip left v-for="(user,index) in $store.state.lessonRoom.users" :key="index">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-list-item
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        >
                                                        <v-list-item-icon>
                                                            <v-icon 
                                                                class="red--text"
                                                                >far fa-user</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{user.last_name}} {{user.name}} {{user.second_name}}</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-btn @click="giveVideo(user.user_id)" v-if="$auth.user().id==1"><v-icon color="primary">mdi-video</v-icon></v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </template>
                                                <span>{{user.last_name}} {{user.name}} {{user.second_name}}</span>
                                            </v-tooltip>
                                        </v-list-item-group>
                                    </v-list>

                                </v-tab-item>
                                <v-tab-item :key="2" eager>
                                    <div  ref="chatMessages" style="overflow-y: scroll;" class="historyLessson">

                                        <div  class="chat-room px-3 py-3" v-chat-scroll="{always: true, smooth: true, scrollonremoved:true}">
                                            <div class="d-flex align-center mb-3" light v-for="message in $store.state.lessonRoom.messages" :key="message.id" :class="{fromMe: message.user.user_id==$auth.user().id, 'messageItem': true}">
                                                <div class="thumb">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span v-if="message.user.user_id==$auth.user().id" v-bind="attrs"
                                                                  v-on="on">
                                                                <v-avatar size="37" class="mx-2" >
                                                                    <img :src="$auth.user().profile.avatar" v-if="$auth.user().profile.avatar"/>
                                                                    <v-icon size="37" v-else>mdi-account</v-icon>
                                                                </v-avatar>

                                                            </span>
                                                            <span v-else v-bind="attrs"
                                                                  v-on="on">
                                                                <v-avatar size="37"  class="mx-2">
                                                                    <img :src="ava(message.user.avatar)" v-if="message.user && message.user.avatar"/>
                                                                    <v-icon size="37" v-else>mdi-account</v-icon>
                                                                </v-avatar>
                                                            </span>
                                                        </template>
                                                        <span v-if="message.user!='system'">{{message.user.last_name}} {{message.user.name}} {{message.user.second_name}}</span>
                                                    </v-tooltip>
                                                </div>
                                                <v-card  :color="message.user.user_id==$auth.user().id ? 'primary': 'grey'" dark class="pa-1 caption white--text" v-html="convMessage(message.text)"></v-card>

                                            </div>
                                            <span id="endLessonChat"></span>
                                        </div>
                                    </div>
                                    <div class="pa-1 border-top" style="bottom: 0px;
                                         position: absolute;
                                         width: 100%;">
                                        <v-textarea no-resize autofocus name="input-4-1" rows="2" ref="newLessonMessageArea" :disabled="sending" v-model="textArea" placeholder="Напишите и нижмите Enter"  @keydown="sendMessage" ></v-textarea>
                                    </div>
                                </v-tab-item>
                            </v-tabs-items>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model='fileModal' max-width="400">
                <v-card>
                    <v-card-title class="headline">Добавить документ</v-card-title>
                    <v-card-text>
                        <v-overlay
                            absolute
                            :value="uploading"
                            >
                        </v-overlay> 
                        <v-list-item-subtitle class="text-wrap">
                            Прикрепите документ и нажмите загрузить
                        </v-list-item-subtitle>
                        <div class="mt-4">
                            <v-file-input show-size v-model="newFile.file" label="Файл" accept=".pdf, .pptx, .docx"></v-file-input>
                            <v-text-field v-model="newFile.name" label="Название"></v-text-field>
                        </div>
                        <v-btn color="primary" v-if="!uploading" @click="upload()">Загрузить</v-btn>
                        <v-progress-linear style="width:100%"
                                           v-if="uploading"
                                           v-model="uploadPercentage"
                                           height="25"
                                           >
                            <template v-slot:default="{ value }">
                                <strong>{{ Math.ceil(value) }}%</strong>
                            </template>
                        </v-progress-linear>
                    </v-card-text>

                </v-card>

            </v-dialog>
            <v-dialog v-model="blockDeleteModal" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Вы уверены?</v-card-title>
                    <v-card-text>После удаления восстановление документа невозможно.</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" text @click="blockDeleteModal = false">Отменить</v-btn>
                        <v-btn color="error" text @click="blockDelete()">Удалить</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="blockRenameModal" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Переименовать</v-card-title>
                    <v-card-text><v-text-field v-model="blockTemp.name" label="Введите новое название"></v-text-field></v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" text @click="blockRenameModal = false">Отменить</v-btn>
                        <v-btn color="primary" text @click="blockRename()">Сохранить</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="fileDeleteModal" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Вы уверены?</v-card-title>
                    <v-card-text>После удаления восстановление документа невозможно.</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" text @click="fileDeleteModal = false">Отменить</v-btn>
                        <v-btn color="error" text @click="fileDelete()">Удалить</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="fileRenameModal" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Переименовать</v-card-title>
                    <v-card-text><v-text-field v-model="fileTemp.name" label="Введите новое название"></v-text-field></v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" text @click="fileRenameModal = false">Отменить</v-btn>
                        <v-btn color="primary" text @click="fileRename()">Сохранить</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
                        <v-dialog v-model="questionDialog" persistent max-width="800">
                <v-card>
                    <v-card-title class="headline">Вопрос преподавателю</v-card-title>
                    
                    <v-card-text>
                        <p>Вопрос адресуется профессорско-преподавательскому составу Академии ГПС МЧС России</p>
                        <v-textarea v-model="question.text" label="Введите вопрос"></v-textarea>
                    <v-checkbox
      v-model="question.video"
      label="Запросить видеоконсультацию"
    ></v-checkbox>
                        </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" text @click="questionDialog = false">Отмена</v-btn>
                        <v-btn color="primary" text @click="questionSend()">Отправить</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<style>
    .v-window{
        height:100%;
    }
    .checkButton{
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.26) !important;
    }
    .wizard-tab-content {
        padding:0px !important;
        height: calc(100vh - 370px)
    }

    .historyLessson::-webkit-scrollbar {
        background: #fff;
        min-width: 16px;
        width: 16px;
        max-width: 16px;
        min-height: 32px;
        height: 32px;
        max-height: 32px;
    }
    .historyLessson::-webkit-scrollbar-thumb {
        background: #3b8be8;
        border-radius: 100px;
        border: 3px solid #e5e5e5;
        box-shadow: inset 0 0 3px #999;
    }

</style>
<script>
    import helper from '@/views/helper'
    import 'array.prototype.move';
    import videoComponent from '@/components/lessons/video'
    import wordComponent from '@/components/lessons/word'
    import preziComponent from '@/components/lessons/prezi'
    import testComponent from '@/components/lessons/test'
    import testProccess from '@/components/lessons/testProccess'
    import videoChat from '@/views/courses/rtc'
    import newLessonBlock from './newLessonBlock'
            var VueScrollTo = require('vue-scrollto');
    export default {
        name: "index",
        data: () => ({
            question:{
              video:false,
              text:''
            },
            questionDialog:false,
                instruction: 0,
                hideControls: false,
                textArea: null,
                sending: false,
                editMod: false,
                interval: null,
                fullVideoOpened: false,
                step: 1,
                blockRenameModal: false,
                blockDeleteModal: false,
                blockTemp: {},
                fileRenameModal: false,
                fileDeleteModal: false,
                fileTemp: {},
                tab: 0,
                uploading: false,
                uploadPercentage: 0,
                newFile: {},
                fileModal: false,
                page: {
                    title: "Урок"
                },
                fileView: null,
                lesson: {},
                loading: true,
                blockHeight: '100px'
            }),
        watch: {
            '$store.state.socket.isConnected'() {
                this.$socket.send(JSON.stringify({action: 'joinLesson', id: this.$route.params.id}))
            },
            '$store.state.encodedVideo'() {
                console.log(this.$store.state.encodedVideo)
                var blockIndex = this.lesson.blocks.findIndex(el => el.id == this.$store.state.encodedVideo.id)

                if (blockIndex) {
                    this.$set(this.lesson.blocks, blockIndex, this.$store.state.encodedVideo)
                }


            }
        },
        components: {
            helper,
            videoChat,
            videoComponent,
            wordComponent,
            preziComponent,
            newLessonBlock,
            testComponent,
            testProccess
        },
        beforeDestroy: function () {
            clearInterval(this.interval)
            window.removeEventListener('resize', () => this.matchHeight())
            this.$socket.send(JSON.stringify({action: 'leftLesson', id: this.$route.params.id}))
        },
        methods: {
            questionSend(){
                          this.$http.post('lesson/question?timetableId=' + this.$route.params.id, 
            this.question
          ).then((response) => {
                    if(response.data && response.data.message){
                        alert(response.data.message)
                    } else {
                        alert('Сообщение отправлено')
                        this.questionDialog=false
                        this.question={text:'',video:false}
                    }
                })
            },
            instructionAccept()
            {
                this.$http.get('lesson/instruction-accept',
                {
                    params: {
                        id: this.$route.params.id
                    }
                }).then(() => {
                    this.instruction = 1
                    this.$nextTick(() => {
                        this.matchHeight()
                    })
                })
            },
            giveVideo(userId) {
                this.$socket.send(JSON.stringify({action: 'giveVideo', id: this.$route.params.id, userId: userId}))
            },
            nl2br(str, is_xhtml) {
                if (typeof str === 'undefined' || str === null) {
                    return '';
                }
                var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
                return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
            },
            convMessage(mes)
            {
                return this.nl2br(mes)
            }, ava(ava) {
                if (!ava)
                {
                    return null
                }
                return ava
            },
            sendMessage(e) {
                if (e.key === 'Enter' && this.textArea) {
                    this.sending = true
                    var text = this.textArea
                    if (text) {
                        this.$socket.send(JSON.stringify({action: 'lessonSendMessage', id: this.$route.params.id, 'text': text}))
                        // JSON responses are automatically parsed.
                        // this.errors = response.data.errors
                        this.textArea = null

                        this.$nextTick(() => {
                            this.sending = false

                        })
                        this.$nextTick(() => {
                            this.$refs.newLessonMessageArea.focus()
                            VueScrollTo.scrollTo("#endLessonChat", 1000, {container: '.historyLessson'})
                        })
                    } else {
                        alert('Напишите сообщение')
                    }
                }
            },
            blockMoveForward(index) {
                this.lesson.blocks.move(index, index + 1)

                this.$nextTick(() => {
                    this.step++
                })
            },
            blockMoveBack(index) {
                this.lesson.blocks.move(index, index - 1)

                this.$nextTick(() => {
                    this.step--



                })


            },
            blockDelete() {
                this.$http.get('lesson/block-delete',
                        {
                            params: {
                                blockId: this.blockTemp.id
                            }
                        }).then(() => {
                    var index = this.lesson.blocks.findIndex(el => el.id == this.blockTemp.id)
                    this.lesson.blocks.splice(index, 1)
                    this.step = index == 0 ? 0 : index
                    this.blockTemp = {}
                    this.blockDeleteModal = false
                })

            },
            blockRename() {
                this.$http.get('lesson/block-rename',
                        {
                            params: {
                                id: this.blockTemp.id,
                                name: this.blockTemp.name,
                            }
                        }).then(() => {
                    var block = this.lesson.blocks.find(el => el.id == this.blockTemp.id)
                    block.name = this.blockTemp.name
                    this.blockTemp = {}
                    this.blockRenameModal = false
                })

            },
            lessonBlockModal(event) {
                console.log(event)
                alert('1');
                return false
            },
            videoOpen(event) {

                this.fileView = 'fullVideo'
                this.$nextTick(()=>{
                 var video = document.getElementById('fullVideo');
                video.srcObject = event;                   
                })


            },
            videoClose() {

                this.fileView = null
            },
            matchHeight() {
                if (this.$refs.biblio && this.$refs.upBlock) {
                    this.$refs.biblio.style.height = document.documentElement.clientHeight - 216 - this.$refs.upBlock.clientHeight + 'px'
                    if (this.$refs.chatMessages) {
                        this.$refs.chatMessages.style.height = document.documentElement.clientHeight - 316 - this.$refs.upBlock.clientHeight + 'px'
                    }
                }
                if (this.$refs.headBlock && this.$refs.lessonWindow) {
                    this.$refs.lessonWindow.style.height = document.documentElement.clientHeight - 128 - this.$refs.headBlock.clientHeight + 'px'
                    this.blockHeight = document.documentElement.clientHeight - 220 - this.$refs.headBlock.clientHeight
                }

            },
            setTime() {
                this.$http.get('lesson/set-time',
                        {
                            params: {
                                id: this.$route.params.id
                            }
                        })
            },
            finishLesson() {
                this.$http.get('lesson/done',
                        {
                            params: {
                                id: this.$route.params.id
                            }
                        }).then(() => {
                    this.$router.push('/')
                })
            },
            fetchData() {
                this.editMod = this.$route.params.mode == 'edit' ? true : false
                if (this.editMod) {
                    this.$http.get('lesson/get-lesson',
                            {
                                params: {
                                    id: this.$route.params.id
                                }
                            }).then(response => {
                        this.lesson = response.data
                        this.instruction = 1
                        this.$nextTick(() => {
                            if (this.instruction == 1) {
                                this.matchHeight()
                            }
                        })
                        this.loading = false
                    })
                } else {
                    this.$http.get('lesson/lesson',
                            {
                                params: {
                                    id: this.$route.params.id
                                }
                            }).then(response => {
                        this.lesson = response.data.lesson
                        this.instruction = response.data.instruction
                        this.$nextTick(() => {
                            this.matchHeight()
                        })
                        this.loading = false
                        if (this.$store.state.socket.isConnected) {
                            this.$socket.send(JSON.stringify({action: 'joinLesson', id: this.$route.params.id}))
                        }
                    })
                }
                if (!this.editMod) {
                    this.interval = setInterval(() => this.setTime(), 10000);
                }
            },
            showFile(index) {
                if (this.fileView == index) {
                    this.fileView = null
                } else {
                    this.fileView = index
                }

            },
            fileDelete() {
                this.$http.get('lesson/file-delete',
                        {
                            params: {
                                id: this.fileTemp.id
                            }
                        }).then(() => {
                    var index = this.lesson.files.findIndex(el => el.id == this.fileTemp.id)
                    this.lesson.files.splice(index, 1)
                    this.fileTemp = {}
                    this.fileDeleteModal = false
                })
            },
            fileRename() {
                this.$http.get('lesson/file-rename',
                        {
                            params: {
                                id: this.fileTemp.id,
                                name: this.fileTemp.name,
                            }
                        }).then(() => {
                    var file = this.lesson.files.find(el => el.id == this.fileTemp.id)
                    file.name = this.fileTemp.name
                    this.fileTemp = {}
                    this.fileRenameModal = false
                })

            },
            upload() {
                this.uploading = true
                var formData = new FormData()
                formData.append("name", this.newFile.name)
                formData.append("file", this.newFile.file)
                this.$http.post('lesson/file-upload?lessonId=' + this.lesson.id,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function (progressEvent) {
                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                            }.bind(this)
                        }
                ).then(response => {
                    this.uploading = false
                    this.fileModal = false
                    this.newFile = {}
                    this.lesson.files.push(response.data)
                }).catch(e => {
                    this.uploading = false
                    alert(e.response.data)
                })
            }
        },
        created() {
            window.addEventListener('resize', () => this.matchHeight())
            this.fetchData()
        }
    };
</script>