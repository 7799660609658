<template>
    <div ref="main" >
        <div style="display:block;">
            <v-img v-if="question.file" contain class="ma-3" max-height="300" :src="question.file">
            </v-img>
        </div>
        <h3 class='ma-3'>{{question.text}}</h3>
        <span v-if='question.several'>*В этом вопросе может быть больше одного ответа</span>
        <v-row class="text-center px-7 pt-3">
            <v-col cols="6" v-for="answer in question.answers" :key="answer.id">
                <v-card elevation="2" class="pa-3"
                        shaped>
                    
                              <v-checkbox class="ma-0"
                                        :multiple="question.several"
                                        :label="answer.text"
                                        v-model="question.correct"
                                        color="green"
                                        :value="answer.id"
                                        hide-details
                                        
                                        ></v-checkbox>
                </v-card>
            </v-col>
        </v-row>
        <v-btn @click="$emit('nextQuestion')" class='ma-5' color='blue'>Следующий вопрос</v-btn>
    </div>
</template>
<style>
    #question textarea{
        display: block;
        text-align: center;
    }
    #question .v-responsive__content{
        width: auto !important;
    }
</style>

<script>
    export default {
        name: "index",
        props: {
            blockId: String,
            editMod: Boolean,
            block: Object,
            height: String,
            question: Object
        },
        data: () => ({
                tempQuestion: {},
                page: 1,
                curAnswer: {},
                answerDeleteModal: false,
                fileModal: false,
                file: null,
                uploading: false,
                uploadPercentage: 0
            }),
        watch: {
            'question'() {
                this.tempQuestion = JSON.parse(JSON.stringify(this.question))
            }
        },
        methods: {
            qustionSetText() {
                this.$http.post('test/set-text?questionId=' + this.tempQuestion.id,
                        {
                            text: this.tempQuestion.text
                        }).then((response) => {
                    this.$set(this.question, 'text', response.data.text)
                })
            },
            addFile() {
                this.question.file = 'https://miro.medium.com/max/4936/1*svd2l7yAE-8LV7pR4bnVIA.png'
            },
            removeFile() {
                this.$http.post('test/file-delete?questionId=' + this.question.id).then(() => {
                    this.question.file = null
                })

            },
            upload() {
                this.uploading = true
                var formData = new FormData()
                formData.append("file", this.file)
                this.$http.post('test/file-upload?questionId=' + this.question.id,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function (progressEvent) {
                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                            }.bind(this)
                        }
                ).then(response => {
                    this.uploading = false
                    this.fileModal = false
                    this.question.file = response.data
                }).catch(e => {
                    this.uploading = false
                    alert(e.response.data)
                })
            },
            answerAdd() {
                this.$http.get('test/answer-add',
                        {
                            params: {
                                questionId: this.question.id
                            }
                        }).then((response) => {
                    if (!this.question.answers) {
                        this.$set(this.question, 'answers', [])
                    }
                    this.question.answers.push(response.data)
                })
            },
            answerSetText() {
                this.$http.post('test/answer-set-text?answerId=' + this.curAnswer.id,
                        {
                            text: this.curAnswer.text
                        }).then((response) => {
                    var index = this.question.answers.findIndex(el => el.id == this.curAnswer.id)
                    this.$set(this.question.answers[index], 'text', response.data.text)
                    this.curAnswer = {}
                })
            },
            answerDelete() {
                this.$http.get('test/answer-delete',
                        {
                            params: {
                                answerId: this.curAnswer.id
                            }
                        }).then(() => {
                    var index = this.question.answers.findIndex(el => el.id == this.curAnswer.id)
                    this.question.answers.splice(index, 1)
                    this.page = index - 1
                    this.answerDeleteModal = false
                })

            },
            answerSetCorrect() {
                this.$http.post('test/answer-set-correct?questionId=' + this.question.id,
                        {
                            correct: this.question.correct
                        }).then((response) => {
                    this.$set(this.question, 'correct', response.data)
                })

            },
        },
        components: {
        },
        mounted() {
            this.tempQuestion = JSON.parse(JSON.stringify(this.question))
            this.$refs.main.style.height = this.height
        }
    };
</script>