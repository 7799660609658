<template>
    <div ref="main" >
        <v-lazy >
            <div id="question">
                <v-toolbar flat tile >
                    <v-toolbar-title><v-tabs :show-arrows="true" v-model="page"><v-tab class="pa-0 ma-0" style="min-width:0px;" v-for="(pageQuestion,index) in questions" :key="pageQuestion.id" @change="question=pageQuestion" @click="question=pageQuestion"><v-btn  outlined  tile>{{index+1}}</v-btn></v-tab></v-tabs>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn-toggle  tile v-if="editMod"><v-btn outlined  tile style='height:36px;' @click="addQuestion()"><v-icon color="green">mdi-plus</v-icon></v-btn><v-btn outlined tile style='height:36px;' v-if="question" @click="questionDeleteModal=true"><v-icon color="error">mdi-delete</v-icon></v-btn></v-btn-toggle>
                </v-toolbar>
                <h1 v-if="questions.length==0">Вопросов нет.</h1>
                <v-btn v-if="editMod && questions.length==0" @click="addQuestion()">Добавить</v-btn>
                <question v-if="Object.keys(question).length!=0" :question.sync="question" :editMod="editMod"/>
                        <v-dialog v-model="questionDeleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>После удаления восстановление вопроса невозможно.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="questionDeleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="deleteQuestion()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

            </div>
        </v-lazy>
        
    </div>
</template>
<script>
    import question from './question'
    export default {
        name: "index",
        props: {
            blockId:String,
            editMod:Boolean,
            height: String
        },
        data: () => ({
            page:0,
            questionDeleteModal:false,
                questions:[],
                question:{},
            }),
        methods: {
            fetchData(){
                            this.$http.get('test/get-questions',
                        {
                            params: {
                                blockId: this.blockId
                            }
                        }).then((response) => {
                    this.questions=response.data
                    if(this.questions.length>0){
                        this.question=this.questions[0]
                    }
                })  
            },
            addQuestion(){
                            this.$http.get('test/add-question',
                        {
                            params: {
                                blockId: this.blockId
                            }
                        }).then((response) => {
                    this.questions.push(response.data)
                    this.page=this.questions.length-1
                    this.question=this.questions[this.questions.length-1]
                })  
            },
            deleteQuestion(){
                            this.$http.get('test/delete-question',
                        {
                            params: {
                                questionId: this.question.id
                            }
                        }).then(() => {
                            var index=this.questions.findIndex(el=>el.id==this.question.id)
                    this.questions.splice(index,1)
                    if(this.questions.length>0)
                    {
                        this.$set(this,'question',this.questions[index-1])
                        this.page=index-1
                    } else {
                        this.$set(this,'question',{})
                    }
                    this.questionDeleteModal=false
                })  
            },
        },
        components: {
            question
        },
        mounted() {
            this.fetchData()
            this.$refs.main.style.height = this.height
        }
    };
</script>