<template>
    <v-container class="text-center" style="overflow-x: hidden;
    overflow-y: auto;height:100%;">
        <div class="mt-4">
            <v-overlay
                absolute
                :value="uploading"
                >
            </v-overlay>      
            <v-select
                v-model="newLessonBlock.type"
                :items="blockTypes"
                label="Выберите тип элемента"
                solo
                ></v-select>
            <div v-if="newLessonBlock.type=='video'">
                <v-alert
                    icon="mdi-alert-outline"
                    outlined
                    text
                    type="info"
                    class="my-4 caption pa-3"
                    >Поддерживаются файлы формата mp4</v-alert>
                <h6
                    class="subtitle-2 font-weight-light"
                    >Выберите файл, укажите название и нажмите кнопку "Добавить".</h6>

                <v-file-input show-size v-model="newLessonBlock.file" label="Файл" accept=".mp4"></v-file-input>
                <v-text-field v-model="newLessonBlock.name" label="Название"></v-text-field>
                <v-btn color="primary"  @click="blockSave()"  v-if="!uploading">Добавить</v-btn>
            </div>
            <div v-if="newLessonBlock.type=='wordText'">
                <v-alert
                    icon="mdi-alert-outline"
                    outlined
                    text
                    type="info"
                    class="my-4 caption pa-3"
                    >Поддерживаются файлы формата docx. Если у ваш файл формата doc, откройте его в текстовом редакторе Word, нажмите "Сохранить как..." и выберите формат docx</v-alert>
                <h6
                    class="subtitle-2 font-weight-light"
                    >Выберите файл, укажите название и нажмите кнопку "Добавить".</h6>

                <v-file-input show-size v-model="newLessonBlock.file" label="Файл" accept=".docx"></v-file-input>
                <v-text-field v-model="newLessonBlock.name" label="Название"></v-text-field>
                <v-btn color="primary" @click="blockSave()"  v-if="!uploading">Добавить</v-btn>
            </div>
            <div v-if="newLessonBlock.type=='prezi'">
                <v-alert
                    icon="mdi-alert-outline"
                    outlined
                    text
                    type="info"
                    class="my-4 caption pa-3"
                    >Поддерживаются файлы формата pptx. Если у ваш файл формата ppt, откройте его в программе PowerPoint, нажмите "Сохранить как..." и выберите формат pptx</v-alert>
                <h6
                    class="subtitle-2 font-weight-light"
                    >Выберите файл, укажите название и нажмите кнопку "Добавить".</h6>

                <v-file-input show-size v-model="newLessonBlock.file" label="Файл" accept=".pptx"></v-file-input>
                <v-text-field v-model="newLessonBlock.name" label="Название"></v-text-field>
                <v-btn color="primary" @click="blockSave()" v-if="!uploading">Добавить</v-btn>
            </div>
            <div v-if="newLessonBlock.type=='pdf'">
                <v-alert
                    icon="mdi-alert-outline"
                    outlined
                    text
                    type="info"
                    class="my-4 caption pa-3"
                    >Поддерживаются файлы формата pdf</v-alert>
                <h6
                    class="subtitle-2 font-weight-light"
                    >Выберите файл, укажите название и нажмите кнопку "Добавить".</h6>

                <v-file-input show-size v-model="newLessonBlock.file" label="Файл" accept=".pdf"></v-file-input>
                <v-text-field v-model="newLessonBlock.name" label="Название"></v-text-field>
                <v-btn color="primary" @click="blockSave()"  v-if="!uploading">Добавить</v-btn>
            </div>
            <div v-if="newLessonBlock.type=='test'">
                <v-text-field v-model="newLessonBlock.name" label="Название"></v-text-field>
                <v-btn color="primary" @click="testSave()"  v-if="!uploading">Добавить</v-btn>
            </div>
            <v-progress-linear style="width:100%"
                               v-if="uploading"
                               v-model="uploadPercentage"
                               height="25"
                               >
                <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                </template>
            </v-progress-linear>

        </div>

    </v-container>


</template>
<style>
    .checkButton{
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.26) !important;
    }
</style>
<script>
    export default {
        name: "index",
        props: {
            blocks: Array,
            lessonId: String,
            step: Number
        },
        data: () => ({
                newLessonBlock: {},
                blockTypes: [
                    {value: 'video', text: "Видеоматериал"},
                    {value: 'wordText', text: "Документ word"},
                    {value: 'prezi', text: "Презентация"},
                    {value: 'pdf', text: "PDF"},
                    {value: 'test', text: "Тестирование"}
                ],
                uploading: false,
                uploadPercentage: 0,
                lesson: {},
                loading: true
            }),
        components: {
        },
        methods: {
            testSave() {
                this.uploading = true
                this.$http.post('https://upload.eios-mchs.ru/v2/lesson/block-test-add?lessonId=' + this.lessonId,
                        {name: this.newLessonBlock.name},
                        {
                            onUploadProgress: function (progressEvent) {
                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                            }.bind(this)
                        }
                ).then(response => {
                    this.uploading = false
                    this.newLessonBlock = {}

                    this.blocks.push(response.data)
                    this.$nextTick(() => {
                        this.$emit('update:step', this.blocks.length + 1)
                    })

                }).catch(e => {
                    this.uploading = false
                    alert(e.response.data)
                })
            },
            blockSave() {
                this.uploading = true
                var formData = new FormData()
                formData.append("name", this.newLessonBlock.name)
                formData.append("type", this.newLessonBlock.type)
                formData.append("file", this.newLessonBlock.file)
                this.$http.post('lesson/block-add?lessonId=' + this.lessonId,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function (progressEvent) {
                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                            }.bind(this)
                        }
                ).then(response => {
                    this.uploading = false
                    this.newLessonBlock = {}

                    this.blocks.push(response.data)
                    this.$nextTick(() => {
                        this.$emit('update:step', this.blocks.length + 1)
                    })

                }).catch(e => {
                    this.uploading = false
                    alert(e.response.data)
                })
            },
        },
        mounted() {
        },
        created() {

        }
    };
</script>